import { get, put,Delete,post } from '../axios'

// banner-列表
export const bannerList = params => get(`/system/web/banner/list`,params);

// banner-新增
export const bannerAdd = params => post(`/system/web/banner/add`,params);

// banner-查看
export const bannerView = params => Delete(`/system/web/banner/view/${params}`);

// banner-编辑
export const bannerUp = params => put(`/system/web/banner/update`,params);

// banner-删除
export const bannerDel = params => Delete(`/system/web/banner/delete/${params}`);

// banner-上下架
export const bannerShelve = params => put(`/system/web/banner/shelve`,params);


