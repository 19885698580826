<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-button type="primary" @click="add" size="mini">新增</el-button>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
      <el-table-column prop="sort" label="排序" min-width="120" />
        <el-table-column prop="title" label="标题" min-width="120" />
        <el-table-column label="轮播图" min-width="120">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="120">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="显示"
              inactive-text="隐藏"
              @change="onchangeIsShow(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small"  icon="el-icon-edit" @click="onEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="form" 
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            class="inputWidth"
            size="mini"
            placeholder="请输入标题"
            v-model="form.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片url:" prop="image">
          <FormImgUpload
            :url="form.image" 
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div class="tipImg">尺寸推荐:670X304，格式：jpeg,png,jpg</div>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            class="inputWidth"
            placeholder="请输入排序"
            size="mini"
            v-model="form.sort"
          ></el-input>
        </el-form-item>
        <el-form-item label="	跳转类型:" prop="type">
          <el-select
            class="inputWidth"
            v-model="form.type"
            size="mini"
            clearable
            @change="selectChange"
            placeholder="请选择"
          >
            <el-option key="1" label="商品分类" value="1"> </el-option>
            <el-option key="2" label="商品详情" value="2"> </el-option>
            <el-option key="3" label="外部链接" value="3"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type == 3" label="外部链接:" prop="url">
          <div class="inputBox">
            <el-input
              class="inputWidth"
              size="mini"
              placeholder="请选择外部链接"
              v-model="form.url"
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item
          v-if="form.type == 2"
          @click.native="openClick"
          label="商品:"
          prop="aboutName"
        >
          <div class="inputBox">
            <el-input
              class="inputWidth"
              size="mini"
              disabled
              placeholder="请选择商品"
              v-model="form.aboutName"
            ></el-input>
            <el-button style="margin-left: 20px" size="mini"
              >选择商品</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="是否上架:" prop="status">
          <el-switch
            v-model="form.status"
            active-value="1"
            inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
          <!-- <el-button @click="resetForm('form')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="选择链接"
      :visible.sync="dialogVisibleLink"
      width="700px"
      :before-close="handleCloseLink"
    >
      <GoodsCard v-if="dialogVisibleLink" @getGoodsItem="getGoodsItem" />
    </el-dialog>
  </div>
</template>
<script>
import {
  bannerList,
  bannerAdd,
  bannerView,
  bannerUp,
  bannerDel,
  bannerShelve,
} from "@/api/banner/banner";
import {
  getStoreAllList, //门店接口
} from "@/api/user/list";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import GoodsCard from "./components/goodsCard";
export default {
  name: "banner",
  components: {
    FormImgUpload,
    GoodsCard,
  },
  data() {
    return {
      title: "新增banner",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        status: "1",
        storeId: "",
      },
      storeList: {},
      form: {
        title:'',
        sort:"",
        type:'',
        url:'',
        status:'',
        image: [],
        storeId: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [{ required: true, message: "上传", trigger: "blur" }],
        sort: [{ required: true, message: "请输入", trigger: "blur" }],
        type: [{ required: true, message: "请输入", trigger: "blur" }],
        storeId: [{ required: true, message: "请选择", trigger: "blur" }],
        aboutName: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getList();
    this.getStoreList();
  },
  methods: {
    //获取门店接口
    getStoreList() {
      getStoreAllList({}).then((res) => {
        this.storeList = res.data;
      });
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
        this.$forceUpdate()
      } else {
        this.form.image = [...this.form.image, e];
        this.$forceUpdate()
      }
    },
    openClick() {
      console.log("123",this.form.storeId);
      this.dialogVisibleLink = true;
    },
    selectChange() {
      console.log("1231");
      this.$forceUpdate();
    },
    getList() {
      this.listLoading = true;
      bannerList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    onchangeIsShow(row) {
      bannerShelve({ id: row.id, status: row.status })
        .then(({ msg }) => {
          this.getList();
          this.$message.success(msg);
        })
        .catch(({ msg }) => {
          this.$message.error(msg);
          this.getList();
        });
    },
    // 删除
    handleDelete(id) {
      let that = this;
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            bannerDel(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  that.getList();
                  that.$message.success(res.msg);
                } else {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
    pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    storeChange(){
      this.form.aboutId = '';
      this.form.aboutName = '';
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.dialogVisible = true;
    },
    // 编辑
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.form.image = [this.form.imgUrl];
      this.form.type = this.form.type.toString();
      this.form.status = this.form.status.toString();
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.title=''
      this.form.image = [];
      this.form.status = 0;
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.type == 2 && !this.form.aboutName) {
            this.$message.error("请选择商品");
            return;
          }
          if (this.form.type == 3 && !this.form.url) {
            this.$message.error("请输入外部链接");
            return;
          }
          if (this.form.id) {
            bannerUp({
              ...this.form,
              imgUrl: this.form.image.length > 0 ? this.form.image[0] : "",
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          } else {
            bannerAdd({
              ...this.form,
              imgUrl: this.form.image.length > 0 ? this.form.image[0] : "",
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  